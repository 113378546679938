import { Avatar, Box, Slide, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TALK_TO_EMPLOYEE } from '../../../../constants';
import { isMobile, isWorkingEmployee } from '../../../../helpers/chat';
import useParent from '../../../../hooks/useParent';
import { talkToEmployee } from '../../../../store/chat/chatSlice';

const EmployeeProfile = ({employee}) => {
    const { t } = useTranslation();
    const { headerColour = '#393939' } = useSelector(state => state.chat.store.chat);
    const { isMobile } = useSelector(state => state.chat);
    const dispatch = useDispatch();
    const { sendMessage } = useParent();
    const isWorking = useMemo(() => isWorkingEmployee(employee), [employee]);
    const connected = useMemo(() => isWorking ? 'en línea' : 'offline', [isWorking]);
    const acceptsMessage = useMemo(() => isWorking || employee.allowOutOfScheduleMessage, [isWorking, employee]);
    const {name = '', avatar = 'https://material-ui.com/static/images/avatar/1.jpg', role = 'Atención'} = employee;
    const handleClick = () => {
        if(!acceptsMessage) return;
        dispatch(talkToEmployee(employee.uid));
        sendMessage(TALK_TO_EMPLOYEE, { employee: employee.uid });
    }
    return (
        <Box
            display={'flex'}
            gap={'10px'}
            alignItems={'center'}
        >
            <Avatar
                alt={name}
                src={avatar}
                sx={{ width: 55, height: 55, border: `2px solid ${acceptsMessage ? headerColour : '#BFBBBD'}`, cursor: 'pointer' }}
                onClick={handleClick}
            />
            <Box
                padding={'10px'}
                sx={{
                    borderRadius: '10px',
                    border: `1px solid ${acceptsMessage ? headerColour : '#BFBBBD'}`,
                    background: '#EEEEEE',
                    cursor: 'pointer'
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="body2"
                    fontSize={isMobile ? '16px' : '14px'}
                    fontWeight={600}
                    sx={{color: acceptsMessage ? '#393939' : '#BFBBBD'}}
                >
                    {name}
                </Typography>
                <Typography
                    variant="body2"
                    fontSize={isMobile ? '14px' : '12px'}
                    fontWeight={400}
                    sx={{color: acceptsMessage ? '#393939' : '#BFBBBD'}}
                >
                    {role} - <span style={{
                        color: acceptsMessage ? '#6E6E6E' : '#BFBBBD',
                        fontStyle: 'italic',
                    }}>({t(connected)})</span>
                </Typography>
            </Box>
        </Box>
    )
}

export const PartnerEmployees = () => {
    const { employees = [] } = useSelector(state => state.chat.store);
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={isMobile ? '17px' : '10px'}
        >
            {employees.map((employee, index) => (
                <EmployeeProfile
                    key={index}
                    employee={employee}
                />
            ))}
        </Box>
        </Slide>
    )
}
