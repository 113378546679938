export const esJSON = {
    //Employee
    'Atención': 'Atención',
    'Juan Perez': 'Juan Perez',

    //Chat
    'Hola 👋🏻': 'Hola 👋🏻',
    'Chat desarrollado por': 'Chat desarrollado por',
    'Volver': 'Volver',
    'en línea': 'en línea',
    'offline': 'offline',
    'Seleccioná el medio por el cual te querés contactar:': 'Seleccioná el medio por el cual te querés contactar:',
};
