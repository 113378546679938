import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ChatPage } from '../pages/ChatPage'

export const ChatRouter = () => {
  return (
    <Routes>
        <Route path="*" element={<ChatPage />} />
    </Routes>
  )
}
