import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

export const PartnerRegularMessage = ({message = ''}) => {
    const { isMobile } = useSelector((state) => state.chat);
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: isMobile ? '15px 20px' : '10px 15px',
                display: 'inline-block',
                overflowWrap: 'break-word',
            }}
        >
            <Typography
                variant="body2"
                sx={{color: '#393939'}}
                fontWeight={400}
                fontSize={isMobile ? '16px' : '14px'}
            >
                {
                    message.split('\n').map((line, index) => {
                        return (
                            <span key={index}>
                                {
                                    line.split(' ').map((word, index) => {
                                        if (word.startsWith('http')) {
                                            return (
                                                <a href={word} target="_blank" rel="noreferrer" key={index}>{word}</a>
                                            )
                                        } else if(word.startsWith('www')) {
                                            return (
                                                <a href={`http://${word}`} target="_blank" rel="noreferrer" key={index}>{`${word} `}</a>
                                            )
                                        }
                                        return word + ' '
                                    }
                                )}
                                <br />
                            </span>
                        )
                    })

                }
            </Typography>
        </Box>
        </Slide>
    )
}