import React from 'react'
import { useSelector } from 'react-redux';
import { ComponentSelector } from '../components/General/ComponentSelector';

export const EmployeeView = () => {
    const { employeeChatHistory = [] } = useSelector((state) => state.chat);
  return (
    <>
       {
        employeeChatHistory.map((item, index) => (
            <ComponentSelector key={index} index={index} />
        ))
       }
    </>
  )
}
