import React from 'react';

// Material Ui
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, responsiveFontSizes } from '@mui/material';
import { chatTheme } from './chatTheme';

// Theme
export const AppTheme = ({ children }) => {
  const theme = responsiveFontSizes(chatTheme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
