import { Box, Slide } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

export const Loading = () => {
    const { headerColour } = useSelector(state => state.chat.store.chat);
    const { isMobile } = useSelector(state => state.chat);
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    background: '#EEEEEE',
                    borderRadius: '14px',
                    width: '25%',
                    padding: isMobile ? '20px' : '15px',
                    alignSelf: 'flex-start',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}
            >
                <Box
                    className='dot-flashing'
                    sx={{
                        color: headerColour,
                        backgroundColor: headerColour,
                        width: isMobile ? '10px' : '8px',
                        height: isMobile ? '10px' : '8px',
                        '&::before': {
                            color: headerColour,
                            backgroundColor: headerColour,
                            width: isMobile ? '10px' : '8px',
                            height: isMobile ? '10px' : '8px',
                            animation: 'myDotFlashing 1s infinite alternate',
                            animationDelay: '0s',
                        },
                        '&::after': {
                            color: headerColour,
                            backgroundColor: headerColour,
                            width: isMobile ? '10px' : '8px',
                            height: isMobile ? '10px' : '8px',
                            animation: 'myDotFlashing 1s infinite alternate',
                            animationDelay: '1s',
                        },
                        animation: 'myDotFlashing 1s infinite alternate',
                        animationDelay: '.5s',
                        "@keyframes myDotFlashing": {
                            "0%": {
                                backgroundColor: headerColour,
                            },
                            "50%": {
                                backgroundColor: '#DADADA',
                            },
                            "100%": {
                                backgroundColor: '#DADADA',
                            }
                        },
                    }}
                >
                </Box>
            </Box>
        </Slide>
    )
}
