import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { backToHomePage } from '../../../store/chat/chatSlice';
import { useTranslation } from 'react-i18next';
import useParent from '../../../hooks/useParent';
import { CLOSE } from '../../../constants';

export const EmployeeHeader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sendMessage } = useParent();
    const { headerColour = '#3F51B5' } = useSelector(state => state.chat.store.chat);
    const { isMobile } = useSelector(state => state.chat);
    const { name = 'Juan Perez', role = 'Atención', avatar = 'https://material-ui.com/static/images/avatar/1.jpg' } = useSelector(state => state.chat.activeEmployee);
    const handleClick = () => {
        dispatch(backToHomePage());
    }
    const handleClose = () => {
        sendMessage(CLOSE);
    }
    return (
        <Box
            sx={{
                background: headerColour,
                borderRadius: '14px 14px 0px 0px',
                padding: isMobile ? '20px 20px' : '10px 15px',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
            }}
        >
            <ArrowBackIosIcon
                sx={{ color: '#FFF', fontSize: isMobile ? '25px' : '20px', cursor: 'pointer' }}
                onClick={handleClick}
            />
            <Avatar alt={name} src={avatar} sx={{ width: 60, height: 60, border: `2px solid ${headerColour} !important` }} />
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'2px'}
            >
                <Typography variant='h2' fontWeight={600} fontSize={isMobile ? '25px' : '20px'} sx={{ color: 'white', marginLeft: 2 }}>{t(name)}</Typography>
                <Typography variant='body2' fontWeight={400} fontSize={isMobile ? '16px' : '14px'} sx={{ color: 'white', marginLeft: 2 }}>{t(role)}</Typography>
            </Box>
            <Box
                position={'absolute'}
                right={0}
                top={0}
                onClick={handleClose}
            >
                <Tooltip placement="top" arrow title={'Cerrar'}>
                    <IconButton
                        sx={{
                            alignSelf: 'center',
                            color: 'white'
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}