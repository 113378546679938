import { Box, Slide, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ANSWER, CLOSE } from '../../../../constants';
import useParent from '../../../../hooks/useParent';
import { renderEmployeesInteraction, renderQuestionAnswer } from '../../../../store/chat/thunks';

export const BackToStore = () => {
    const { t } = useTranslation();
    const { fontColour = '#393939', backButtonText = 'Volver' } = useSelector((state) => state.chat.store.chat);
    const { isMobile } = useSelector((state) => state.chat);
    const { sendMessage } = useParent();
    const handleClick = () => {
        sendMessage(CLOSE);
    };
    return (
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: isMobile ? '15px 20px' : '10px 15px',
                display: 'inline-block',
                alignSelf: 'flex-end',
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            <Typography
                variant="body2"
                sx={{ color: fontColour }}
                fontWeight={500}
                fontSize={isMobile ? '16px' : '14px'}
            >
                {t(backButtonText)}
            </Typography>
        </Box>
    )
}

export const QuestionMessage = ({question, uid}) =>{
    const { fontColour } = useSelector(state => state.chat.store.chat);
    const { isMobile } = useSelector(state => state.chat);
    const { sendMessage } = useParent();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(renderQuestionAnswer(uid))
        sendMessage(ANSWER, { answer: uid });
    }
    return (
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: isMobile ? '15px 20px' : '10px 15px',
                display: 'inline-block',
                alignSelf: 'flex-end',
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            <Typography
                variant="body2"
                sx={{color: fontColour}}
                fontWeight={500}
                fontSize={isMobile ? '16px' : '14px'}
            >
                {question}
            </Typography>
        </Box>
    )

}

export const TalkToEmployeeMessage = ({ message = ''}) => {
    const {botonColour = '#FFC107'} = useSelector((state) => state.chat.store.chat);
    const { isMobile } = useSelector((state) => state.chat);
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(renderEmployeesInteraction(message));
    }
    return (
            <Box
                sx={{
                    background: botonColour,
                    borderRadius: '14px',
                    maxWidth: '80%',
                    width: 'fit-content',
                    padding: isMobile ? '15px 20px' : '10px 15px',
                    display: 'inline-block',
                    alignSelf: 'flex-end',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="body2"
                    sx={{ color: '#FFFFFF' }}
                    fontWeight={500}
                    fontSize={isMobile ? '16px' : '14px'}
                >
                    {message}
                </Typography>
            </Box>
    )
}

export const ClientOptions = () => {
    const { hasEmployees, isMobile } = useSelector((state) => state.chat);
    const { answers } = useSelector((state) => state.chat.store);
    const { talkToEmployee = 'Hablar con un asesor' } = useSelector((state) => state.chat.store.chat); 
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box
                display='flex'
                flexDirection='column'
                gap={isMobile ? '17px' : '10px'}
            >
                {answers.map((answer, index) => (
                    <QuestionMessage
                        key={index}
                        {...answer}
                    />
                ))}
                {hasEmployees && (<TalkToEmployeeMessage message={talkToEmployee}/>)} 
                {(answers.length > 0 || hasEmployees) && <BackToStore/>}                 
            </Box>
        </Slide>
    )
}
