//Messages types
export const USER_REGULAR_MESSAGE = 'REGULAR_MESSAGE';
export const PARTNER_REGULAR_MESSAGE = 'PARTNER_REGULAR_MESSAGE';
export const USER_SELECTED_MESSAGE = 'USER_SELECTED_MESSAGE';
export const OPTIONS_MESSAGE = 'OPTIONS_MESSAGE';
export const EMPLOYEE_OPTIONS_MESSAGE = 'EMPLOYEE_OPTIONS_MESSAGE';
export const LOADING_MESSAGE = 'LOADING_MESSAGE';
export const EMPLOYEE_CHANNEL_OPTIONS_MESSAGE = 'EMPLOYEE_CHANNEL_OPTIONS_MESSAGE';


//PARENT SON COMMUNICATION
export const CLOSE = 'CLOSE';
export const REDIRECT = 'REDIRECT';
export const ANSWER = 'ANSWER'; 
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const TALK_TO_EMPLOYEE = 'TALK_TO_EMPLOYEE';

//TIMES
export const TIME_TO_SHOW_OPTIONS = 4000;
export const TIME_TO_SHOW_LOADING = 500;
export const TIME_TO_SHOW_ANSWER = 2000;
export const TIME_TO_SHOW_EMPLOYEE_OPTIONS = 500;
