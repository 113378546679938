import { Grid, IconButton, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { getMobileOperatingSystem, isWorkingEmployee } from '../../../helpers/chat';
import { useSelector } from 'react-redux';
import useParent from '../../../hooks/useParent';
import { CLOSE, SEND_MESSAGE, UPDATE_MESSAGE } from '../../../constants';

export const SendMessageInput = () => {

    const { activeEmployee: employee, inputChat } = useSelector((state) => state.chat);
    const { uid } = employee;
    const isWorking = useMemo(() => isWorkingEmployee(employee), [employee]);
    const { botonColour = '#393939' } = useSelector((state) => state.chat.store.chat);
    const [message, setMessage] = React.useState('');
    const { sendMessage } = useParent();

    const handleClick = () => {
        const { prefix, phoneNumber } = employee.phoneNumber;
        const phone = `${prefix}${phoneNumber}`;


        if (inputChat.channel === 'WhatsApp') {
            window.open(`https://wa.me/${phone}?text=${message}`, '_blank');
        } else {
            //send SMS
            const device = getMobileOperatingSystem();
            if (device === 'iOS') {
                window.open(`sms:${phone}&body=${message}`, '_blank');
            } else {
                window.open(`sms:${phone}?body=${message}`, '_blank');
            }
        }
        sendMessage(SEND_MESSAGE, {message, isWorking, uid});
        sendMessage(CLOSE);
        setMessage('');
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            sx={{
                backgroundColor: 'transparent',
                borderRadius: '10px',
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '90%',
                zIndex: '1000',
            }}
        >
            <Grid item xs={10}
                sx={{
                    background: 'white',
                    padding: 1,
                    borderRadius: '10px',
                    border: `1px solid ${botonColour}`,
                }}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    maxRows={4}
                    minRows={1}
                    variant="standard"
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value);
                        sendMessage(UPDATE_MESSAGE, {message: e.target.value});
                    }}
                    fullWidth
                    placeholder="Hola, quería saber..."
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                />
            </Grid>
            <Grid item xs={2}
                alignSelf='flex-end'
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                marginBottom={0.5}
            >
                <IconButton
                    size="medium"
                    onClick={handleClick}
                    sx={{
                        background: botonColour,
                        '&:hover': {
                            background: botonColour,
                        },
                    }}
                >
                    <SendIcon sx={{ color: '#FFF' }} />
                </IconButton>
            </Grid>
        </Grid>
    )
}
