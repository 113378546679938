export const ptJSON = {
    //Employee
    'Atención': 'Atenção',
    'Juan Pérez': 'Juan Perez',

    //Chat
    'Hola 👋🏻': 'Olá 👋🏻',
    'Chat desarrollado por': 'Chat desenvolvido por',
    'Volver': 'Voltar',
    'en línea': 'online',
    'offline': 'offline',
    'Seleccioná el medio por el cual te querés contactar:': 'Selecione o meio pelo qual você quer se contactar:',
};
