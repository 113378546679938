import { Avatar, AvatarGroup, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { isAnyWorkingEmployee } from '../../../helpers/chat';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useParent from '../../../hooks/useParent';
import { CLOSE } from '../../../constants';


export const HomeHeader = () => {
    const { sendMessage } = useParent();
    const { t } = useTranslation();
    const { chat, employees = [] } = useSelector(state => state.chat.store);
    const { isMobile } = useSelector(state => state.chat);
    const { headerColour = '#2ED588', title = 'Hola! 👋🏻' } = chat;

    const handleClose = () => {
        sendMessage(CLOSE);
    }
    return (
        <Box
            sx={{
                background: headerColour,
                borderRadius: '14px 14px 0px 0px',
                padding: isMobile ? '30px 20px' : '15px 15px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
            }}
        >
            <Typography
                variant="h2"
                fontWeight={600}
                fontSize={isMobile ? '28px' : '24px'}
                sx={{ color: 'white' }}
            >
                {t(title)}
            </Typography>
            <AvatarGroup max={3} spacing='small' sx={{ marginRight: 1, '& .MuiAvatar-root': { backgroundColor: headerColour } }}>
                {
                    isAnyWorkingEmployee(employees) && employees.map((employee, index) => {
                        return (
                            <Avatar key={index} alt={employee.name} src={employee.avatar} sx={{ width: 45, height: 45, border: `2px solid ${headerColour ?? '#01C66B'} !important`, backgroundColor: headerColour }} />
                        )
                    }
                    )
                }
            </AvatarGroup>
            <Box
                position={'absolute'}
                right={0}
                top={0}
                onClick={handleClose}
            >
                <Tooltip placement="top" arrow title={'Cerrar'}>
                    <IconButton
                        sx={{
                            alignSelf: 'center',
                            color: 'white'
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
