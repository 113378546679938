import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'


export const ClientSelectedMessage = ({ message = ''}) => {
    const {botonColour = '#FFC107'} = useSelector((state) => state.chat.store.chat);
    const { isMobile } = useSelector((state) => state.chat);
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    background: botonColour,
                    borderRadius: '14px',
                    maxWidth: '80%',
                    width: 'fit-content',
                    padding: isMobile ? '15px 20px' : '10px 15px',
                    display: 'inline-block',
                    alignSelf: 'flex-end',
                    cursor: 'pointer',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ color: '#FFFFFF' }}
                    fontWeight={500}
                    fontSize={isMobile ? '16px' : '14px'}
                >
                    {message}
                </Typography>
            </Box>
        </Slide>
    )
}