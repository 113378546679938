import { TIME_TO_SHOW_ANSWER, TIME_TO_SHOW_EMPLOYEE_OPTIONS, TIME_TO_SHOW_LOADING, TIME_TO_SHOW_OPTIONS } from "../../constants";
import { initStore, renderAnswer, renderCurrentQuestion, renderEmployees, renderLoading, renderOptions, renderTalkToEmployee, talkToEmployee } from "./chatSlice"



export const beginChat = (chat) => {
    return async (dispatch) => {
        dispatch(initStore(chat));
        setTimeout(() => {
            dispatch(renderOptions());
        }, TIME_TO_SHOW_OPTIONS);
    }
}

export const renderQuestionAnswer = (answerQuestionId) => {
    return async (dispatch) => {
        dispatch(renderCurrentQuestion(answerQuestionId));
        setTimeout(() => {
            dispatch(renderLoading());
            setTimeout(() => {
                dispatch(renderAnswer(answerQuestionId));
                setTimeout(() => {
                    dispatch(renderOptions());
                }, TIME_TO_SHOW_OPTIONS);
            }, TIME_TO_SHOW_ANSWER);
        }, TIME_TO_SHOW_LOADING);
    }
}

export const renderEmployeesInteraction = (message) => {
    return async (dispatch, getState) => {
        dispatch(renderTalkToEmployee(message));
        setTimeout(() => {
            const { talkToEmployees, store } = getState().chat;
            if(talkToEmployees && store.employees.length === 1){
                dispatch(talkToEmployee(store.employees[0].uid))
            }
            dispatch(renderEmployees());
            setTimeout(() => {
                dispatch(renderOptions());
            }, TIME_TO_SHOW_OPTIONS);
        }, TIME_TO_SHOW_EMPLOYEE_OPTIONS);
    }
}