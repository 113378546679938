import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ChatRouter } from '../chat/router/ChatRouter'

export const AppRouter = () => {
  return (
    <Routes>
        <Route path="/*" element={<ChatRouter />} />
    </Routes>
  )
}
