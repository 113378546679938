export const enJSON = {
    //Employee
    'Atención': 'Attention',
    'Juan Perez': 'Juan Perez',

    //Chat
    'Hola! 👋🏻': 'Hello! 👋🏻',
    'Chat desarrollado por': 'Chat developed by',
    'Volver': 'Back',
    'en línea': 'online',
    'offline': 'offline',
    'Seleccioná el medio por el cual te querés contactar:': 'Select the medium by which you want to contact:',
}