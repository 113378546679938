import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { esJSON, mxJSON, ptJSON, enJSON } from './translation';

const resources = {
  es: { translation: esJSON },
  pt: { translation: ptJSON },
  mx: { translation: mxJSON },
  en: { translation: enJSON },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  fallbackLng: 'es',
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
