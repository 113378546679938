import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { AppRouter } from "./router/AppRouter";
import { AppTheme } from "./theme/AppTheme";

export const ChatApp = () => {
  return (
    <AppTheme>
      <I18nextProvider i18n={i18n}>
        <AppRouter />
      </I18nextProvider>
    </AppTheme>
  )
};
