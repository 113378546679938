import { createSlice } from "@reduxjs/toolkit";
import {
  EMPLOYEE_CHANNEL_OPTIONS_MESSAGE,
  EMPLOYEE_OPTIONS_MESSAGE,
  LOADING_MESSAGE,
  OPTIONS_MESSAGE,
  PARTNER_REGULAR_MESSAGE,
  USER_REGULAR_MESSAGE,
  USER_SELECTED_MESSAGE,
} from "../../constants";
import {
  getEmployeeRigthMessage,
  isAnyWorkingEmployee,
  isMobile,
  trueAnswers,
  trueEmployees,
} from "../../helpers/chat";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    store: {
      answers: [
        {
          question: "¿Realizan Cambios?",
          reply:
            "Si, nos encanta que estés feliz con tu compra!\n\nHacemos cambios teniendo en cuenta los siguientes requisitos:\n\n✅ Dentro de los 60 días de haber recibido tu compra\n✅ Productos nuevos, sin uso con su packaging original\n✅ \n\n👉 En caso de requerir un cambio por un error imputable a Mini Anima (envio mal realizado, el producto no corresponde, el producto presenta una falla) el costo de envío de IDA Y VUELTA lo asumiremos nosotros.\n\n👉 En caso de solicitar un cambio por otro producto, o por error al realizar la compra (seleccionar mal talle, modelo, color, etc) el envío de IDA Y VUELTA corre por cuenta del comprador.\n \n⚠️ Importante: Los productos de FERIA, DISCONTINUOS o SEGUNDA SELECCION no tienen cambio ni devolución. \n\n🙏 Por favor, te recomendamos que al momento de hacer tu pedido te tomes tu tiempo para revisar que estás eligiendo correctamente el producto y talle que necesitas.\n\nEsperamos tu pedido!",
          state: true,
          createdAt: "2023-02-01T15:02:44.856Z",
          updatedAt: "2023-02-04T13:37:00.266Z",
          uid: "63da7f146fa253ca16ce602e",
        },
        {
          question: "¿Formas de Pago?¿Hacen envíos a todo el país?",
          reply:
            "Aceptamos todos los medios de pago y hacemos envíos a TODO el país! Te cuento un poco más:\n\n💵 MEDIOS DE PAGO:\n- 10% OFF x Transferencia Bancaria\n- Tarjetas de Crédito (3 y 6 cuotas sin interés Plan Ahora)\n- Tarjeta de Débito\n- Efectivo a través de PagoFacil o RapiPago\n- Mercado Pago\n- Local Mar del Plata: 20% OFF Contado Efectivo\n\n📦 FORMAS DE ENVIO:\n\n🚚 Envios a TODO EL PAIS:\nSuper rápidos y económicos por OCA pudiendo elegir recibir tu pedido en Domicilio o en una Sucursal Oca.\n\n🛵 Servicio de Mensajeria Flash para envíos en Mar del Plata.\n\nPara conocer el costo y tiempo de envío podés usar el calculador de envío que se encuentra dentro de cada producto en nuestra Tienda Online.\n\n👉 ENVÍO GRATIS a todo el país en pedidos superiores a $14999 😉",
          state: true,
          createdAt: "2023-02-01T15:02:44.857Z",
          updatedAt: "2023-02-04T13:37:00.131Z",
          uid: "63da7f146fa253ca16ce602f",
        },
        {
          question: "Pedidos Mar del Plata",
          reply:
            "👉 Nuestro Local de Mar del Plata está ubicado en la calle Tucuman 4656. Podés visitarnos en los siguientes dias y horarios:\n\n🕣 Lunes a Viernes de 8 a 15:30\n🕣 Sábados de 9 a 13 y de 17 a 20. \n\nEn caso que prefieras podés hacer tu pedido en nuestra Tienda Online y solicitar envío con Mensajeria a cualquier punto de la ciudad por un valor de $300 (Envío gratis en compras mayores a $14999)\n\n💵 PROMO EFECTIVO: 20% OFF abonando en Local o al Mensajero.\n💳 3 y 6 CUOTAS SIN INTERÉS\n\nTe esperamos ❤️",
          state: true,
          createdAt: "2023-02-01T15:02:44.857Z",
          updatedAt: "2023-02-04T13:37:00.216Z",
          uid: "63da7f146fa253ca16ce6030",
        },
        {
          question: "¿Realizan venta Mayorista?",
          reply:
            'Muchas Gracias por tu consulta!\n\nEn este momento no estamos incorporando nuevos clientes mayoristas ya que estamos con la producción / venta a tope.\n\nSi algo cambia vamos a estar anunciándolo por Instagram 🤞en historias!!! \n\nNos encantaría que nos dejes tus datos para futuras aperturas del canal mayorista.\n\nPor favor, completá el siguiente formulario y ya estarás formando parte de nuestra Base de Datos!\n\nhttps://forms.gle/687uEdMG7oYVyVvo7\n\nEn caso que prefieras, podés hacer tu compra minorista en nuestra Tienda Online aprovechando hasta 6 cuotas sin interés con tarjeta de crédito, 10% off por transferencia y envío gratis en compras mayores a $14.999!\n\nSi sos “Responsable Inscript@“ o "Monotributista"  hacemos Factura A y si sos "Consumidor Final” Factura B.\n\nMuchas Gracias! ❤️',
          state: true,
          createdAt: "2023-02-01T15:02:44.857Z",
          updatedAt: "2023-02-04T13:37:00.277Z",
          uid: "63da7f146fa253ca16ce6031",
        },
      ],
      chat: {
        feedbackMessage: {
          message: "Click para chatear",
          state: false,
          timeReveal: 10000,
        },
        visible: true,
        title: "Hola! 👋🏻",
        description: "👋🏻 ¡Hola! Bienvenid@ a Mini! Estamos para ayudarte ❤️",
        botonColour: "#2ED588",
        iconColour: "#8C8C8C",
        headerColour: "#2ED588",
        chatColour: "#ffffff",
        fontColour: "#2ED588",
        xPosition: {
          left: false,
          right: 20,
        },
        yPosition: 20,
        delayButtonAppear: 0,
        backgroundImg:
          "https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat1.png?alt=media&token=36a52494-e329-44df-bb2d-448d281224af",
        answersTemplate: null,
        createdAt: "2023-02-01T15:02:44.846Z",
        updatedAt: "2023-02-04T13:36:59.921Z",
      },
      employees: [
        {
          name: '"Meli" Alvarez',
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/63da7edc6fa253ca16ce5e4b%2Fimages%2Fba60569a-3a0c-46da-8b44-52df938068f4.png?alt=media&token=ba60569a-3a0c-46da-8b44-52df938068f4",
          phoneNumber: {
            phoneNumber: "2236228373",
            prefix: "54",
            country: "ar",
            countryCode: "54",
          },
          role: "Cambios & Gestiones Post Venta",
          schedule: [
            {
              state: true,
              start: 480,
              end: 960,
              days: [1, 2, 3, 4, 5],
              _id: "63de621f6fa253ca16008b3e",
            },
            {
              state: true,
              start: 540,
              end: 600,
              days: [],
              _id: "63de621f6fa253ca16008b3f",
            },
            {
              state: true,
              start: 1080,
              end: 1140,
              days: [],
              _id: "63de621f6fa253ca16008b40",
            },
          ],
          allowOutOfScheduleMessage: true,
          state: true,
          welcomeMessage:
            "¡Hola! 👋🏻 Gracias por comunicarte conmigo, con gusto responderé TODAS tus consultas! 😃",
          outMessage:
            "🙋‍♀️ Hola! Gracias por comunicarte conmigo. En este momento estamos fuera nuestro horario de atención personalizada.\n\nEn cuanto regrese, estaré encantada de responder tu consulta 🤗.",
          channels: ["whatsapp"],
          createdAt: "2023-02-01T20:43:42.287Z",
          updatedAt: "2023-02-04T13:48:15.529Z",
          uid: "63dacefe6fa253ca16d34861",
        },
        {
          name: '"Mada" Petrucci',
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/63da7edc6fa253ca16ce5e4b%2Fimages%2F93dc57d4-5f80-4c25-a028-2fbfb82ff9f6.png?alt=media&token=93dc57d4-5f80-4c25-a028-2fbfb82ff9f6",
          phoneNumber: {
            phoneNumber: "2235361436",
            prefix: "54",
            country: "ar",
            countryCode: "54",
          },
          role: "Mar del Plata",
          schedule: [
            {
              state: true,
              start: 480,
              end: 840,
              days: [1, 2, 3, 4, 5],
              _id: "63de62186fa253ca16008ab3",
            },
            {
              state: true,
              start: 540,
              end: 600,
              days: [],
              _id: "63de62186fa253ca16008ab4",
            },
            {
              state: true,
              start: 1080,
              end: 1140,
              days: [],
              _id: "63de62186fa253ca16008ab5",
            },
          ],
          allowOutOfScheduleMessage: true,
          state: true,
          welcomeMessage:
            "¡Hola! 👋🏻 Gracias por comunicarte conmigo, con gusto responderé TODAS tus consultas! 😃",
          outMessage:
            "🙋‍♀️ Hola! Gracias por comunicarte conmigo. En este momento estamos fuera nuestro horario de atención personalizada.\n\nEn cuanto regrese, estaré encantada de responder tu consulta 🤗.",
          channels: ["whatsapp"],
          createdAt: "2023-02-01T20:41:01.996Z",
          updatedAt: "2023-02-04T13:48:08.155Z",
          uid: "63dace5d6fa253ca16d33e0d",
        },
      ],
      name: "Mini Anima SRL",
      state: true,
    },
    hasEmployees: false,
    talkToEmployees: false,
    inputChat: {
      show: false,
      channel: "whatsapp",
    },
    isMobile: true,
    activeEmployee: null,
    isEmployeePage: false,
    homeHistory: [],
    employeeChatHistory: [],
    chatRef: null,
  },
  reducers: {
    initStore: (state, action) => {
      state.store = action.payload;
      if (isAnyWorkingEmployee(action.payload.employees)) {
        state.hasEmployees = true;
      }
      state.store.answers = trueAnswers(action.payload.answers);
      state.isMobile = isMobile();
      state.store.employees = trueEmployees(action.payload.employees);
      state.homeHistory = [
        {
          payload: {
            message: state.store.chat.description,
          },
          type: PARTNER_REGULAR_MESSAGE,
        },
      ];
    },
    renderOptions: (state, action) => {
      state.homeHistory = [
        ...state.homeHistory,
        {
          payload: {},
          type: OPTIONS_MESSAGE,
          scroll: false,
        },
      ];
    },
    renderCurrentQuestion: (state, action) => {
      state.homeHistory = state.homeHistory.slice(0, -1);
      state.homeHistory = [
        ...state.homeHistory,
        {
          payload: {
            message: state.store.answers.find(
              (item) => item.uid === action.payload
            ).question,
          },
          type: USER_REGULAR_MESSAGE,
          scroll: true,
        },
      ];
    },
    renderAnswer: (state, action) => {
      state.homeHistory = state.homeHistory.filter(
        (item) => item.type !== LOADING_MESSAGE
      );
      state.homeHistory = [
        ...state.homeHistory,
        {
          payload: {
            message: state.store.answers.find(
              (item) => item.uid === action.payload
            ).reply,
          },
          type: PARTNER_REGULAR_MESSAGE,
          scroll: false,
        },
      ];
      state.store.answers = state.store.answers.filter(
        (item) => item.uid !== action.payload
      );
    },
    renderLoading: (state, action) => {
      state.homeHistory = [
        ...state.homeHistory,
        {
          payload: {},
          type: LOADING_MESSAGE,
          scroll: true,
        },
      ];
    },
    renderTalkToEmployee: (state, action) => {
      state.talkToEmployees = true;
      state.homeHistory[state.homeHistory.length - 1] = {
        payload: {
          message: action.payload,
        },
        type: USER_SELECTED_MESSAGE,
        scroll: true,
      };
    },
    renderEmployees: (state, action) => {
      state.talkToEmployees = false;
      state.homeHistory = [
        ...state.homeHistory,
        {
          payload: {},
          type: EMPLOYEE_OPTIONS_MESSAGE,
            scroll: true,
        },
      ];
    },
    renderInputChat: (state, action) => {
      state.inputChat = action.payload;
    },
    talkToEmployee: (state, action) => {
      const activeEmployee = state.store.employees.find(
        (item) => item.uid === action.payload
      );
      state.employeeChatHistory = [
        {
          payload: {
            message: getEmployeeRigthMessage(activeEmployee),
          },
          type: PARTNER_REGULAR_MESSAGE,
        },
        {
          payload: {},
          type: EMPLOYEE_CHANNEL_OPTIONS_MESSAGE,
        }
      ];
      state.activeEmployee = activeEmployee;
      state.isEmployeePage = true;
    },
    backToHomePage: (state, action) => {
      state.isEmployeePage = false;
      state.activeEmployee = null;
      state.employeeChatHistory = [];
      state.homeHistory[state.homeHistory.length - 1].scroll = true;
      state.inputChat = {
        show: false,
        channel: "whatsapp",
      };
    },
  },
});

// export const { initStore,renderOptions, talkToEmployees, talkToEmployee, backToHomePage } = chatSlice.actions;
//need to dynamically import actions
export const {
  initStore,
  renderOptions,
  renderAnswer,
  renderCurrentQuestion,
  renderLoading,
  renderInputChat,
  talkToEmployees,
  talkToEmployee,
  renderTalkToEmployee,
  renderEmployees,
  backToHomePage,
} = chatSlice.actions;
