import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { EMPLOYEE_CHANNEL_OPTIONS_MESSAGE, EMPLOYEE_OPTIONS_MESSAGE, LOADING_MESSAGE, OPTIONS_MESSAGE, PARTNER_REGULAR_MESSAGE, USER_REGULAR_MESSAGE, USER_SELECTED_MESSAGE } from '../../../constants';
import { ClientOptions } from '../Messages/Client/ClientOptions';
import { ClientRegularMessage } from '../Messages/Client/ClientRegularMessage';
import { ClientSelectedMessage } from '../Messages/Client/ClientSelectedMessage';
import { Loading } from '../Messages/General/Loading';
import { EmployeesChannels } from '../Messages/Merchant/EmployeesChannels';
import { PartnerEmployees } from '../Messages/Merchant/PartnerEmployees';
import { PartnerRegularMessage } from '../Messages/Merchant/PartnerRegularMessage';

export const ComponentSelector = ({ index }) => {
    const { isEmployeePage, employeeChatHistory, homeHistory } = useSelector((state) => state.chat);
    const element = useMemo(() => {
        if (isEmployeePage) {
            return employeeChatHistory[index];
        } else {
            return homeHistory[index];
        }
    }, [isEmployeePage, employeeChatHistory, homeHistory, index]);

    switch (element.type) {
        case USER_REGULAR_MESSAGE:
            return <ClientRegularMessage message={element.payload.message} />
        case USER_SELECTED_MESSAGE:
            return <ClientSelectedMessage message={element.payload.message} />
        case PARTNER_REGULAR_MESSAGE:
            return <PartnerRegularMessage message={element.payload.message} />
        case OPTIONS_MESSAGE:
            return <ClientOptions/>
        case EMPLOYEE_OPTIONS_MESSAGE:
            return <PartnerEmployees/>
        case EMPLOYEE_CHANNEL_OPTIONS_MESSAGE:
            return <EmployeesChannels/>
        case LOADING_MESSAGE:
            return <Loading/>
        default:
            return <Loading/>
    }
}
