import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isWorkingEmployee } from '../../../../helpers/chat';
import { renderInputChat } from '../../../../store/chat/chatSlice';
import { PartnerRegularMessage } from './PartnerRegularMessage';

const channelToText = (channel) => {
    switch (channel) {
        case 'sms':
            return 'SMS';
        case 'whatsapp':
            return 'WhatsApp';
        case 'cellphone':
            return 'Llamada Telefónica';
        default:
            return 'WhatsApp';
    }
}

const displayInputChat = ({ employee, selectedChannel }) => {
    return (isWorkingEmployee(employee) || employee.allowOutOfScheduleMessage) && ["SMS", "WhatsApp"].includes(selectedChannel);
}

const displayOptions = ({ employee }) => {
    return (isWorkingEmployee(employee) || employee.allowOutOfScheduleMessage);
}

const EmployeeChannelOption = ({ channel, selectedChannel, setSelectedChannel}) => {
    const { headerColour, fontColour } = useSelector(state => state.chat.store.chat);
    const { activeEmployee: employee, isMobile } = useSelector(state => state.chat);
    const dispatch = useDispatch();

    const handleClick = () => {
        setSelectedChannel(channel);
        dispatch(renderInputChat({
            channel: channel,
            show: displayInputChat({ employee: employee, selectedChannel: channel })
        }));
        if (channel === 'Llamada Telefónica') {
            const { prefix, phoneNumber } = employee.phoneNumber;
            const phone = `${prefix}${phoneNumber}`;
            window.open(`tel:${phone}`);
        }
    }

    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    background: selectedChannel ? headerColour : '#FFF',
                    borderRadius: '14px',
                    maxWidth: '80%',
                    width: 'fit-content',
                    padding: isMobile ? '15px 20px' : '10px 15px',
                    display: 'inline-block',
                    border: `1px solid ${headerColour}`,
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="body2"
                    sx={{ color: selectedChannel ? '#FFF' : fontColour }}
                    fontWeight={400}
                    fontSize={isMobile ? '16px' : '14px'}
                >
                    {channel}
                </Typography>
            </Box>
        </Slide>
    )
}

export const EmployeesChannels = () => {
    const { t } = useTranslation();
    const { activeEmployee: employee, isMobile } = useSelector(state => state.chat);
    const { headerColour, fontColour } = useSelector(state => state.chat.store.chat);
    const dispatch = useDispatch();

    const moreThanOneChannel = employee.channels.length > 1;

    const [channels, setChannels] = React.useState([]);

    React.useEffect(() => {
        const channels = employee.channels.map(channel => channelToText(channel));
        channels.sort();
        setChannels(channels);
        if (!moreThanOneChannel && ["SMS", "WhatsApp"].includes(channels[0])) {
            dispatch(renderInputChat({
                channel: channels[0],
                show: true
            }));
        }
    }, [employee, moreThanOneChannel, dispatch]);

    const [selectedChannel, setSelectedChannel] = React.useState(null);

    const showOptions = (moreThanOneChannel || employee.channels[0] === 'cellphone') && displayOptions({ employee });

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={isMobile ? '17px' : '10px'}
        >
            {showOptions && <PartnerRegularMessage message={t('Seleccioná el medio por el cual te querés contactar:')}/>}
            <Box
                display="flex"
                gap="10px"
                flexWrap="wrap"
            >
                {showOptions && channels.map((channel, index) => {
                    return (
                        <EmployeeChannelOption
                            key={index}
                            channel={channel}
                            headerColour={headerColour}
                            fontColour={fontColour}
                            selectedChannel={selectedChannel === channel}
                            setSelectedChannel={setSelectedChannel}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
