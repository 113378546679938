import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

export const ChatLayout = ({children}) => {
  const { backgroundImg } = useSelector(state => state.chat.store.chat)
  return (
    <Box
    sx={{
        borderRadius: '14px',
        boxShadow: 2,
        height: '100vh',
        width: '100vw',
        background: '#FFF',
        backgroundImage: backgroundImg ? `url(${backgroundImg})` : 'url(https://i.ibb.co/PZ7xwNz/wpp-pattern.png)',
        overflow: 'hidden',
    }}
    className="chat-frame"
>
    {children}
</Box>
  )
}
