import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { useFetch } from 'use-http';
import { MAIN_URL_API } from '../../configuration';
import { REDIRECT } from '../../constants';
import useParent from '../../hooks/useParent';
import { beginChat } from '../../store/chat/thunks';
import { EmployeeHeader } from '../components/Header/EmployeeHeader';
import { HomeHeader } from '../components/Header/HomeHeader';
import { SendMessageInput } from '../components/Inputs/SendMessageInput';
import { ChatLayout } from '../layout/ChatLayout'
import { EmployeeView } from '../views/EmployeeView';
import { HomeView } from '../views/HomeView';

export const ChatPage = () => {
  const { t } = useTranslation();
  const { sendMessage } = useParent();
  const { isEmployeePage, inputChat, isMobile, homeHistory } = useSelector((state) => state.chat);
  const bottomRef = useRef(null);
  const topRef = useRef(null);
  const handleClick = () => {
    sendMessage(REDIRECT);
  };

  
  //TODO: Remove all of this after the chat is working
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
    const chatId = urlParams.get('storeId') ?? '5123321232325';
    const origin = urlParams.get('origin') ?? 'Tiendanube';
  const { get, response } = useFetch(MAIN_URL_API);
  useEffect(() => {
    async function getChat() {
        const data = await get('/fullchat/' + chatId + '?origin=' + origin);
        if (response.ok) {
            dispatch(beginChat(data));
        }
    }
    getChat();
}, []);


  useEffect(() => {
    if (bottomRef.current && !isEmployeePage && homeHistory.length > 0 && homeHistory[homeHistory.length - 1].scroll) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if(isEmployeePage && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [bottomRef, homeHistory, isEmployeePage]);

  useEffect(() => {
    if(!isEmployeePage && homeHistory.length > 0 ) bottomRef.current.scrollIntoView({ behavior: 'auto' });
  }, [isEmployeePage]);

  return (
    <ChatLayout>
      {
        isEmployeePage
          ? <EmployeeHeader />
          : <HomeHeader />
      }
      <Box
        sx={{
          padding: '6px 20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: '#393939', cursor: 'pointer' }}
          textAlign={'right'}
          fontWeight={400}
          fontSize={isMobile ? '16px' : '12px'}
          onClick={handleClick}
        >
          {t('Chat desarrollado por')} <span style={{ fontWeight: 600 }}>WhatsPRO</span>
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={isMobile ? '17px' : '10px'}
          className="chat-frame"
          sx={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 150px)' : 'calc(100vh - 115px)',
          }}
          
        >
          <Box marginBottom={0.1} ref={topRef}></Box>
          {
            isEmployeePage
              ? <EmployeeView />
              : <HomeView />
          }
          <Box marginTop={20}></Box>
          <Box marginTop={2} ref={bottomRef}></Box>
          {inputChat.show && <SendMessageInput/>}
        </Box>
      </Box>
    </ChatLayout>
  )
}
