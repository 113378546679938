import { useSelector } from 'react-redux';
import { ComponentSelector } from '../components/General/ComponentSelector';

export const HomeView = () => {
    const { homeHistory } = useSelector((state) => state.chat);
    return (
        <>
            {
                homeHistory.map((item, index) => (
                    <ComponentSelector 
                        key={index} 
                        index={index} 
                        />
                ))
            }
        </>
    )
}
