
import { useState, useEffect } from 'react';

const useParent = () => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      setMessage(event);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const sendMessage = (type, payload) => {
    let newMessage = { type, payload };
    const stringMessage = JSON.stringify(newMessage);
    window.parent.postMessage(stringMessage, '*');
  };

  return { message, sendMessage };
};

export default useParent;
